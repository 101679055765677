import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { fetchIntersectDates, customDayContent as renderCustomDayContent } from './utils/taskFormUtils'; // Reuse the custom day renderer from taskFormUtils
import '../../assets/stylesheets/components/_read-only-date-range-picker.scss';

const ReadOnlyDateRangePicker = ({ startDate, endDate, selected, recurringType, interval }) => {

  const [intersectDates, setIntersectDates] = useState([]);

  const values = {
    task_start_date: startDate,
    task_end_date: endDate,
    selected: selected,
    recurring_type: recurringType,
    interval: interval,
  };

  const parseDateLiteral = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // El mes en JavaScript empieza desde 0, por eso restamos 1
  };

  const dateRange = [
    {
      startDate: parseDateLiteral(startDate),
      endDate: parseDateLiteral(endDate),
      key: 'selection',
    },
  ];

  useEffect(() => {
    fetchIntersectDates(values, setIntersectDates);
  }, [startDate, endDate, selected, recurringType, interval]);

  return (
    <div className="task-form read-only" >
      <DateRange
        rangeColors={['#000052']}
        ranges={dateRange}
        editableDateInputs={false}
        showSelectionPreview={true}
        months={2}
        direction='horizontal'
        moveRangeOnFirstSelection={false}
        disabled={true}
        showDateDisplay={true}
        showMonthAndYearPickers={false}
        dayContentRenderer={(day) => renderCustomDayContent(day, intersectDates, dateRange)}
        preventSnapRefocus={true}
        staticRanges={[]}
        inputRanges={[]}
        dateDisplayFormat='MM/dd/yyyy'
      />
    </div>
  );
};

export default ReadOnlyDateRangePicker;
