export const initializeTaskData = () => {
  const today = new Date();
  const startOfToday = new Date(today.setHours(0, 0, 0, 0));

  return {
    name: '',
    description: '',
    task_type: 1,
    task_start_date: startOfToday,
    task_end_date: startOfToday,
    task_window_start: '00:00',
    task_window_end: '00:01',
    recurring_type: 'week',
    interval: 1,
    selected: [0, 1, 2, 3, 4, 5, 6],
    shared_task: false,
    officer_ids: [],
    inmate_ids: [],
    shift_id: '',
    active: true,
  };
};

export const updateHiddenFields = (key, value) => {
  const field = document.getElementById(`${key}_field`);
  if (field) {
    if (Array.isArray(value)) {
      field.value = value.join(',');
    } else {
      field.value = value;
    }
  }
};
