import React from 'react';
import { Select, Checkbox, InputNumber } from 'antd';

// Handle tab change logic
export const handleTabChange = (value, onChange, setActiveTab, setMonthlyFrequencyOption) => {
  setActiveTab(value);

  onChange('selected', []);
  onChange('recurring_type', '');
  setMonthlyFrequencyOption('specificDay');

  if (value === 'daily') {
    onChange('recurring_type', 'week');
    onChange('selected', [0, 1, 2, 3, 4, 5, 6]);
  } else if (value === 'custom') {
    onChange('recurring_type', 'week');
    onChange('interval', '');
  } else {
    onChange('recurring_type', value);
    onChange('interval', 1);
  }
};

// Handle custom frequency type change
export const handleCustomFrequencyChange = (value, onChange, setCustomFrequencyType) => {
  setCustomFrequencyType(value);
  onChange('recurring_type', value);

  if (value === 'week') {
    onChange('selected', []);
  } else if (value === 'month') {
    onChange('selected', []);
    onChange('day_ordinal', '');
  }
};

// Render Weekly tab content
export const renderWeeklyTab = (taskData, onChange) => (
  <div className='form-group weekdays'>
    <label className='label'>Days of the Week:</label>
    <div className='flex'>
      {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(
        (day, index) => (
          <div key={day}>
            <Checkbox
              checked={(taskData.selected || []).includes(index)}
              onChange={() => {
                const newDays = taskData.selected.includes(index)
                  ? taskData.selected.filter((d) => d !== index)
                  : [...taskData.selected, index];
                onChange('selected', newDays);
              }}
            >
              {day}
            </Checkbox>
          </div>
        )
      )}
    </div>
  </div>
);

// Render Monthly tab content
export const renderMonthlyTab = (
  taskData,
  onChange,
  monthlyFrequencyOption,
  setMonthlyFrequencyOption
) => (
  <div className='form-group'>
    <div className='form-group'>
      <label className='label'>Choose Monthly Frequency Type:</label>
      <Select
        style={{ width: '220px' }}
        value={monthlyFrequencyOption}
        onChange={(value) => {
          setMonthlyFrequencyOption(value);
          const recurringType = value === 'specificDay' ? 'month' : 'monthday';
          onChange('recurring_type', recurringType);
          onChange('selected', []);
        }}
        options={[
          { value: 'specificDay', label: 'Specific Day of the Month' },
          { value: 'ordinalDay', label: 'Ordinal and Day of the Week' },
        ]}
      />
    </div>

    {monthlyFrequencyOption === 'specificDay' && (
      <div className='form-group'>
        <label htmlFor='monthlyDay' className='label'>
          Select specific day of the month:
        </label>
        <Select
          style={{ width: '220px' }}
          value={taskData.selected[0]}
          onChange={(value) => onChange('selected', [value])}
          options={[
            ...Array.from({ length: 28 }, (_, i) => ({ value: i + 1, label: `${i + 1}` })),
            { value: 'last', label: 'Last' },
          ]}
        />
      </div>
    )}

    {monthlyFrequencyOption === 'ordinalDay' && (
      <>
        <div className='form-group flex'>
          {/* <label htmlFor='selectedOrdinal' className='label'>
            Select ordinal:
          </label>
          <Select
            style={{ width: '220px' }}
            value={taskData.day_ordinal || ''}
            onChange={(value) => onChange('day_ordinal', value)}
            options={[
              { value: 'first', label: 'First' },
              { value: 'second', label: 'Second' },
              { value: 'third', label: 'Third' },
              { value: 'fourth', label: 'Fourth' },
              { value: 'last', label: 'Last' },
            ]}
          /> */}
          <label htmlFor='monthlyDay' className='label'>
            First
          </label>

          <Select
            style={{ width: '220px' }}
            value={taskData.selected[0]}
            onChange={(value) => onChange('selected', [value])}
            options={[
              { value: 0, label: 'Sunday' },
              { value: 1, label: 'Monday' },
              { value: 2, label: 'Tuesday' },
              { value: 3, label: 'Wednesday' },
              { value: 4, label: 'Thursday' },
              { value: 5, label: 'Friday' },
              { value: 6, label: 'Saturday' },
            ]}
          />
          <label htmlFor='monthlyWeekday' className='label'>
            of the month
          </label>
        </div>
      </>
    )}
  </div>
);

// Render Custom tab content
export const renderCustomTab = (
  taskData,
  onChange,
  customFrequencyType,
  setCustomFrequencyType,
  monthlyFrequencyOption,
  setMonthlyFrequencyOption
) => (
  <div className='form-group' style={{ marginBottom: '2rem' }}>
    <label htmlFor='customInterval' className='label'>
      Every:
    </label>
    <div className='flex' style={{ marginBottom: '2rem' }}>
      <InputNumber
        min={1}
        value={taskData.interval}
        onChange={(value) => onChange('interval', value)}
      />
      <Select
        style={{ width: '200px' }}
        value={customFrequencyType}
        onChange={(value) => handleCustomFrequencyChange(value, onChange, setCustomFrequencyType)}
        options={[
          { value: 'week', label: 'Week' },
          { value: 'month', label: 'Month' },
        ]}
      />
    </div>

    {customFrequencyType === 'week' && renderWeeklyTab(taskData, onChange)}

    {customFrequencyType === 'month' &&
      renderMonthlyTab(taskData, onChange, monthlyFrequencyOption, setMonthlyFrequencyOption)}
  </div>
);
