import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Input, Select, Checkbox } from 'antd';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../../assets/stylesheets/components/_task-form.scss';
import TaskFrequencyTabs from './TaskFrequencyTabs';
import {
  fetchIntersectDates,
  validationSchema,
  customDayContent,
  handleDateRangeChange,
  handleFieldChange,
} from './utils/taskFormUtils';

const TaskForm = ({ taskData, onChange, shifts, users, inmates, taskTypes, sharedTasks }) => {
  const [intersectDates, doSetIntersectDates] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [assignToInmate, setAssignToInmate] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: taskData.name || '',
      description: taskData.description || '',
      task_window_start: taskData.task_window_start || '',
      task_window_end: taskData.task_window_end || '',
      task_start_date: taskData.task_start_date
        ? new Date(taskData.task_start_date).toISOString()
        : new Date().toISOString(),
      task_end_date: taskData.task_end_date
        ? new Date(taskData.task_end_date).toISOString()
        : new Date().toISOString(),
      officer_ids: taskData.officer_ids || [],
      inmate_ids: taskData.inmate_ids || [],
      shared_task: taskData.shared_task || false,
      task_type: taskData.task_type || 0,
      selected: taskData.selected || [],
      interval: taskData.interval || 1,
      recurring_type: taskData.recurring_type || 'week',
    },

    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values) => {
      console.log('Validated Values:', values);
      values;
    },
  });

  const setIntersectDates = useCallback(
    (values) => {
      fetchIntersectDates(values, doSetIntersectDates); // Use utility function
    },
    [doSetIntersectDates]
  );

  useEffect(() => {
    formik.setFieldTouched('officer_ids', true, false);
  }, []);

  return (
    <div className='task-form'>
      {/* Task name */}
      <div className='form-group'>
        <label htmlFor='name' className='label'>
          Task Name:
        </label>
        <Input
          name='name'
          value={formik.values.name}
          onChange={(e) => handleFieldChange(formik, 'name', e.target.value, onChange)}
          onBlur={formik.handleBlur}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='error-message'>{formik.errors.name}</div>
        )}
      </div>
      {/* Task description */}
      <div className='form-group'>
        <label htmlFor='description' className='label'>
          Task Description:
        </label>

        <Input.TextArea
          name='description'
          value={formik.values.description}
          onChange={(e) => handleFieldChange(formik, 'description', e.target.value, onChange)}
          onBlur={formik.handleBlur}
        />
        {formik.touched.description && formik.errors.description && (
          <div className='error-message'>{formik.errors.description}</div>
        )}
      </div>
      {/* Task type */}
      <div className='flex'>
        <div className='form-group'>
          <label htmlFor='taskType' className='label'>
            Assign to:
          </label>
          <Select
            value={formik.values.task_type}
            onChange={(value) => handleFieldChange(formik, 'task_type', Number(value), onChange)} // Convert to number if necessary
            style={{ width: '200px' }}
          >
            {
              taskTypes.map((taskType) => (
              <Select.Option key={taskType.value} value={taskType.value}>
                {taskType.label}
              </Select.Option>
            ))}
          </Select>

          {formik.touched.task_type && formik.errors.task_type && (
            <div className='error-message'>{formik.errors.task_type}</div>
          )}
        </div>

        {/* Conditional Select for Shifts or Officers */}
        {formik.values.task_type === 0 && (
          <div className='form-group'>
            <label htmlFor='shift_id' className='label'>
              Select Shift:
            </label>
            <Select
              value={formik.values.shift_id}
              onChange={(value) => {
                handleFieldChange(formik, 'shift_id', value, onChange);
                handleFieldChange(formik, 'shared_task', true, onChange)
              }}
              style={{ width: '200px' }}
            >
              {shifts.map((shift) => (
                <Select.Option key={shift.id} value={shift.id}>
                  {shift.name}
                </Select.Option>
              ))}
            </Select>
            {formik.touched.shift_id && formik.errors.shift_id && (
              <div className='error-message'>{formik.errors.shift_id}</div>
            )}
          </div>
        )}

        {formik.values.task_type === 1 && (
          <div className='flex'>
            <div className='form-group'>
              <label htmlFor='officer_ids' className='label'>
                Select Officers:
              </label>
              <Select
                mode='multiple'
                value={formik.values.officer_ids}
                onChange={(value) => handleFieldChange(formik, 'officer_ids', value, onChange)}
                style={{ minWidth: '200px' }}
              >
                {users.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.full_name}
                  </Select.Option>
                ))}
              </Select>
              {formik.touched.officer_ids && formik.errors.officer_ids && (
                <div className='error-message'>{formik.errors.officer_ids}</div>
              )}
            </div>
            <div className='form-group'>
              <label htmlFor='sharedTask' className='label'>
                Responsibility:
              </label>
              <Select
                value={formik.values.shared_task}
                onChange={(value) => handleFieldChange(formik, 'shared_task', value, onChange)}
                style={{ width: '200px' }}
              >
                {sharedTasks.map(([label, value]) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        )}
      </div>
      <div className='flex' style={{ minHeight: '100px' }}>
        {/* Assign to Specific Inmate Checkbox */}
        <div className='form-group'>
          <Checkbox
            checked={assignToInmate}
            onChange={(e) => {
              setAssignToInmate(e.target.checked);
              handleFieldChange(formik, 'assignToInmate', e.target.checked, onChange); // Handle change correctly
            }}
          >
            Assign to Specific Inmate?
          </Checkbox>
        </div>

        {/* Select for Inmates if Checkbox is Checked */}
        {assignToInmate && (
          <div className='form-group'>
            <label htmlFor='inmate_ids' className='label'>
              Select Inmates:
            </label>
            <Select
              mode='multiple'
              value={formik.values.inmate_ids}
              onChange={(value) => handleFieldChange(formik, 'inmate_ids', value, onChange)}
              style={{ minWidth: '200px' }}
            >
              {inmates.map((inmate) => (
                <Select.Option key={inmate.id} value={inmate.id}>
                  {inmate.full_name} - {inmate.id}
                </Select.Option>
              ))}
            </Select>
            {formik.touched.inmate_ids && formik.errors.inmate_ids && (
              <div className='error-message'>{formik.errors.inmate_ids}</div>
            )}
          </div>
        )}
      </div>
      <div className='flex'>
        {/* Date range picker */}
        <div className='form-group'>
          <label htmlFor='dateRange' className='label'>
            Select Date Range:
          </label>
          <DateRange
            rangeColors={['#000052']}
            color='#000'
            onChange={(item) => {
              const task_start_date = item.selection.startDate;
              const task_end_date = item.selection.endDate;

              handleDateRangeChange(item, setDateRange, handleFieldChange, formik, onChange);

              setIntersectDates({ ...formik.values, task_start_date, task_end_date });
            }}
            editableDateInputs={true}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={dateRange}
            direction='horizontal'
            minDate={new Date()}
            dayContentRenderer={(day) => customDayContent(day, intersectDates, dateRange)}
            dateDisplayFormat='MM/dd/yyyy'
          />

          {formik.touched.task_start_date && formik.errors.task_start_date && (
            <div className='error-message'>{formik.errors.task_start_date}</div>
          )}
          {formik.touched.task_end_date && formik.errors.task_end_date && (
            <div className='error-message'>{formik.errors.task_end_date}</div>
          )}
        </div>
        <div>
          {/* Task window start */}
          <div className='form-group'>
            <label htmlFor='task_window_start' className='label'>
              Task Window Start:
            </label>
            <Input
              type='time'
              name='task_window_start'
              value={formik.values.task_window_start}
              onChange={(e) =>
                handleFieldChange(formik, 'task_window_start', e.target.value, onChange)
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.task_window_start && formik.errors.task_window_start && (
              <div className='error-message'>{formik.errors.task_window_start}</div>
            )}
          </div>

          {/* Task window end */}
          <div className='form-group'>
            <label htmlFor='task_window_end' className='label'>
              Task Window End:
            </label>
            <Input
              type='time'
              name='task_window_end'
              value={formik.values.task_window_end}
              onChange={(e) =>
                handleFieldChange(formik, 'task_window_end', e.target.value, onChange)
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.task_window_end && formik.errors.task_window_end && (
              <div className='error-message'>{formik.errors.task_window_end}</div>
            )}
          </div>
        </div>
      </div>

      {/* Frequency Tabs */}
      <div className='form-group' style={{ minHeight: '300px' }}>
        <label className='label'>Frequency:</label>
        <TaskFrequencyTabs
          taskData={formik.values}
          onChange={(field, value) => {
            handleFieldChange(formik, field, value, onChange);
            setIntersectDates({ ...formik.values, [field]: value });
          }}
        />
      </div>
    </div>
  );
};

export default TaskForm;
